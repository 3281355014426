/**
* infused
*/
@import 'infused/infused';

/**
* style
*/
$black: #2c2c2c;
$red: #ff0000;
$green: #558B2F;
$grey: #424242;
$blue: #12416a;

%hover{
	transition: opacity 0.15s ease-out;
	cursor: pointer;
	-webkit-backface-visibility: hidden; // odd opacity transition bug
	opacity: 0.9;

	&:hover{
		opacity: 1;
	}
}

%hoverBackground{
	@extend %hover;

	transition: background 0.15s ease-out;
}

@import 'layout/typo';
@import 'layout/layout';
