/**
* pleaserespond depends on jeet, breakpoint
* @todo standalone bower package, and add to infused bower package as dependency
*/

$wrap-width: 95%!default;
$wrap-max-width: 1200px!default;

$mobile: 0px!default;
$phablet: 550px!default;
$tablet: 750px!default;
$laptop: 1200px!default;
$desktop: 1600px!default;

html{
	font-size: 62.5%;
}

// usage: .wrap{@extend %wide;}
%wide{
	width: 95%; max-width: 100%;
}

.wrap{
	@include selfclear();
	
	position: relative;
	width: $wrap-width; max-width: $wrap-max-width;
	margin: 0 auto;
}