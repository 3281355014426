$opensans: 'Open Sans', sans-serif;

strong{
	font-weight: 600;
}

.typography{
	font-size: 2rem; color: $black; font-weight: 200;
	padding: 1.4em 0 1em;

	h1{
		font-size: 2.5em; font-weight: 700;
		margin-bottom: 0.15em;
	}

	h2{
		font-size: 1.25em; font-weight: 500; font-style: italic;
		margin-bottom: 0.6em;
	}

	h3{
		font-size: 1em; font-weight: 400;
		margin-bottom: 0.75em;
	}

	p{
		margin-bottom: 0.75em;
	}

	blockquote{
		font-size: 1.25em; text-align: center;
		margin: 1em 0.5em;

		@include breakpoint($tablet) {
			margin-right: 2em; margin-left: 2em;
		}
	}

	a{
		color: $red; text-decoration: none;
		transition: color 0.15s ease-in;

		&:hover{
			color: lighten($red, 10%);
		}
	}

	strong{
		font-weight: 400;
	}
}