@mixin blockify(){
	display: block;
	font-size: 0px; text-indent: -9999px; line-height: 0;
}

@mixin mask(){
	position: absolute; top: 0; right: 0; bottom: 0; left: 0;
}

@mixin selfclear(){
	&::after{
		content: "";
		display: table;
		clear: both;	
	}
}

/**
 * Center an element on either or both axes.
 * Temporary adaptation of jeet's align to include vendor prefixes until I implement cssautoprefixer
 * @requires A parent container with relative positioning.
 * @param {string} [$direction=both] - Specify which axes to center the element on.
 */
@mixin align($direction: both) {
	position: absolute;
	transform-style: preserve-3d;

	@if index("horizontal" "h", $direction) {
		left: 50%;
		transform: translateX(-50%);
		-webkit-transform: translateX(-50%);
	} @else if index("vertical" "v", $direction) {
		top: 50%;
		transform: translateY(-50%);
		-webkit-transform: translateY(-50%);
	} @else {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		-webkit-transform: translate(-50%, -50%);
	}
}