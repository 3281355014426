html, body{
	background: #FBFBFB;
	font-family: $opensans; color: $black; line-height: 1.3; font-weight: 400;
	height: 100%;
	overflow: hidden;
}

#app, .Root, .Viewport{
	height: 100%;
}

#intercom-launcher.intercom-launcher-enabled{
	visibility: hidden;
}

.Viewport{
	display: flex;
	flex-direction: column;
	align-items: stretch;

	position: relative;
	background: url('/images/img_waiting_room.jpg'); background-size: cover;
	overflow: hidden;

	&.interview{background-image: url('/images/img_desk.jpg');}
	&.examination{background-image: url('/images/img_desk.jpg');}
	&.hospital{background-image: url('/images/img_doctor.jpg');}

	.Top{
		@include selfclear();

		background: #fff;
		font-size: 1.7em; line-height: 4; font-weight: 600;
		width: 100%;
		box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.28);
		z-index: 1;

		.Back{
			float: left;
			cursor: pointer;

			a{
				@extend %hoverBackground;

				display: block;
				color: $blue; text-decoration: none;
				padding: 0 2em 0 1.5em; margin-left: -1.5em;

				span{
					vertical-align: middle;
				}

				.icon{
					font-size: 22px;
					margin-right: 0.25em;
				}

				&:hover{
					background: rgba(255,255,255,0.2);
				}
			}
		}

		.logos{

			a{

				img{
					display: inline-block;
					width: 125px;
					vertical-align: middle;
				}

				&.SydneyUniversity{margin-right: 1.25em;}
				&.SaveSightInstitute{}
			}
		}

		.Back + .logos{
			text-align: center;
		}

		.User{
			float: right;

			a{
				@extend %hoverBackground;

				display: block;
				color: $blue; text-decoration: none;
				padding: 0 1.5em 0 2em; margin-right: -1.5em;

				span{
					vertical-align: middle;
				}

				.icon{
					position: relative; top: -1px;
					font-size: 22px;
					margin-right: 0.25em;
				}

				&:hover{
					background: rgba(255,255,255,0.2);
				}
			}
		}
	}

	.Modal{
		display: flex; align-items: center; justify-content: center;
		position: absolute; top: 100%; right: 0; bottom: 0; left: 0;
		background: rgba(0,0,0,0.25);
		color: #fff;
		padding: 25px;
		z-index: 5;
		opacity: 0;
		transition: opacity 0.35s ease-in-out;

		&.open{
			top: 0;
			opacity: 1;
		}

		.window{
			display: flex; flex-direction: column;
			background: #CECECE;
			width: 100%; height: 100%;
			overflow: visible;

			%title-font{
				display: block;
				font-size: 1.8em; font-weight: 600; line-height: 48px;
			}

			.top{
				flex: 0;
				height: 48px;
				position: relative;
				background: #fff;
				color: $blue; text-align: center;

				.title{
					@extend %title-font;
					div{
						@extend %hover;

						display: inline-block;

						&:hover,
						&.current{
							text-decoration: underline;
						}
					}
				}

				.icon{
					@extend %hover;

					position: absolute; top: 8px; right: 8px;
					font-size: 32px;
				}
			}

			.switch-container{
				flex: 0;
				position: relative;

				> div{
					height: 48px;
					display: flex;
					flex-direction: row;
					color: darken(#fff, 25);
					background: $blue;

					> div{
						flex: 1 0;
						text-align: center;

						&:hover {
							background: lighten($blue, 10);
							color: #fff;
						}

						&.selected {
							color: darken(#fff, 10);

							span {
								border-width: 0 0 0.5em;
								height: 48px;
								border-style: solid;
								border-bottom-color: #ffcf40;
							}
						}

						span {
							@extend %title-font;
							cursor: pointer;
							width: 100%;
						}

						&:first-child {
							span {
								border-left: 0;
							}
						}
						&:last-child {
							span {
								border-right: 0;
							}
						}
					}
				}
			}

			.middle{
				flex: 2;
				height: calc(100% - 96px); width: 100%;
				position: relative;

				.Image{
					position: absolute;
					top:0; right: 0; left: 0; bottom: 0;
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					justify-content: center;
					align-items: center;
					align-content:center;
					overflow: hidden;

					figure {
						width: 100%;
						height: 100%;
						margin: 0;
						overflow:hidden;

						figcaption {
						}


						img {
							max-height: calc(100% - 1.8em);
							max-width: 100%;
							margin: 0 auto;
						}
					}
				}

				.viewer-container {
						background: #CECECE;
				}

				h3{
					font-size: 2em; color: $blue; font-weight: 400; text-align: center;
					margin-top: 1.5em;
				}
			}
		}
	}

	.Middle{
		flex: 1;
		display: flex; align-items: stretch;
		font-size: 1.3em; color: #2c2c2c;
		width: 100%;
		overflow-y: auto;

		.Login{
			flex: 1;
			display: flex;
			align-items: center; justify-content: center;
			background: url('/images/interview.jpg') 50% 50%; background-size: cover;
			font-size: 1.2em; text-align: center;

			.form{

				> .icon{
					display: block;
					font-size: 120px; color: #fff; font-weight: 400;
					margin-bottom: 0.1em;
				}

				input,
				button{
					display: block;
					background: #fff;
					border: 1px solid #efefef;
					text-align: center;
					padding: 0.9em 1em; margin-bottom: 0.2em;
					border-radius: 2px;
				}

				input{
					color: $black;
					width: 300px;
					// fix chrome's yellow background on autocomplete
					-webkit-box-shadow: 0 0 0px 1000px white inset;
				}

				button{
					@extend %hover;

					background: lighten($blue, 15%);
					border: 0 none;
					color: #fff;
					width: 65%;
					margin: 2.5em auto 0;
				}
			}
		}

		// @todo shift to scss/views/
		.Cases{
			flex: 1;
			display: flex;
			align-items: center; justify-content: center;

			.wrap{
				display: block;

				h1{
					font-size: 2em; color: $blue; font-weight: 600; text-align: center;
					margin-bottom: 2em;
				}

				ul{
					display: flex;
					flex: 1; flex-direction: row;
					align-items: center; justify-content: center;
					flex-wrap: wrap;
					max-width: 900px;
					margin: 0 auto;

					li{
						position: relative;
						flex: 1;
						text-align: center;
						padding: 3em 3.5em;

						a{
							@extend %hover;

							position: relative;
							display: block;
							font-size: 1.6em; color: $blue; line-height: 1.5; font-weight: 600; text-decoration: none; text-align: center;
							z-index: 2;

							.icon.case{
								display: block;
								font-size: 70px;
								margin-bottom: 0.15em;
								opacity: inherit;
							}

							&.inProgress{
							}

							&.completed{
								opacity: 0.25!important;
							}
						}

						.icon-check{
							@include align();

							font-size: 6em; color: #114A11;
						}

						.status{
							position: absolute; left: 0;

							font-size: 1.1em; color: $blue; text-align: center; font-style: italic;
							width: 100%;
							cursor: pointer;
						}
					}
				}
			}

			.copyright{
				position: absolute; bottom: 2.5em; left: 0;
				font-size: 0.9em; text-align: center;
				width: 100%;

				a{
					@extend %hover;

					display: inline-block;
					font-size: 1.2em; color: #2c2c2c; text-align: center; text-decoration: none;
					opacity: 0.25;

					strong{
						display: block;
						font-weight: 400;
						margin: 0 auto 0.35em;
					}

					img{
						width: 5em;
						margin: 0 auto;
					}
				}
			}
		}

		.Case{
			flex: 1;
			display: flex;

			.Interview{
				flex: 1;
				display: flex;

				.left{
					flex: 1.25;
					display: flex;

					.note{
						font-weight: 300; font-style: italic; text-align: center;
						margin-bottom: 0.75em;
					}

					.Questions{
						flex: 1;
						display: flex;
						align-items: center; justify-content: center;
						min-width: 850px;

						.top{
							background: #fff;
							font-size: 1.3em; color: $blue; font-weight: 600; line-height: 70px;
						}

						.Categories{
							display: none;
							background: rgba(255,255,255,0.7);
							font-size: 1.1em; text-align: center; font-weight: 600;
							width: 100%; min-height: 605px;
							border-radius: 0.25em;

							&.isVisible{
								display: block;
							}

							.top{
								color: lighten($blue, 15%); text-align: center;
							}

							.middle{
								padding: 1.1em;

								.row{
									display: flex; justify-content: center;
									padding: 1em 0;

									&:last-child{
										border-bottom: 0 none;
									}

									.Question{
										position: relative;
										display: inline-block;
										text-align: center;
										width: 7em;
										margin: 0 1em;
										vertical-align: top;

										.icon{
											display: block;
											font-size: 36px;

											@include breakpoint($phablet) {
												font-size: 40px;
											}

											@include breakpoint($tablet) {
												font-size: 45px;
											}

											@include breakpoint($laptop) {
												font-size: 52px;
											}
										}

										.name{

										}
									}
								}

								// differentiate Systemic Viewer
								.row:last-child .Question:last-child{
									border-left: 1px solid #e4e4e4;
								}
							}
						}

						.Category{
							flex: 1;
							background: rgba(255,255,255,0.7);
							font-size: 1.1em;
							width: 100%;
							margin: 1em;
							border-radius: 0.25em;

							.top{
								position: relative;
								border-bottom: 1px solid rgba(0,0,0,0.1);

								> .icon{
									@extend %hover;

									position: absolute; top: 50%; left: 0;
									font-size: 24px; color: $blue;
									padding: 0 0.75em; margin-top: -12px; margin-right: 0.25em;
								}

								.icon,
								.name{
									color: $blue;
								}

								.Question{
									padding: 0; margin-left: 45px;

									&.hasPlayed{
										opacity: 1;
									}

									.icon{
										font-size: 40px;
										margin-right: 0.2em;
										vertical-align: middle;
									}

									.tellMeMore,
									.playing{
										right: 0!important;
										font-size: 0.4em!important;
									}

									.playing{
										margin-right: 0;
									}
								}
							}

							.middle{
								padding: 0.5em 0;
								max-height: 550px;
								overflow-y: auto;

								.questions{
									@include selfclear();

									.Question{
										@include span(1/2, $cycle: 2);

										position: relative;
										font-size: 1em;
										padding: 0.7em 1.25em;

										@include breakpoint($tablet) {
											font-size: 1.1em;
										};

										@include breakpoint($laptop) {
											font-size: 1.2em;
											padding: 1em 1.25em;
										};

										.icon-play{
											font-size: 40px;
											margin-right: 0.1em;
										}

										.tellMeMore,
										.playing{
											right: 0;
											font-size: 0.4em!important;
										}

										span{
											vertical-align: middle;
										}
									}
								}
							}
						}
					}
				}

				.right{
					flex: 1;
					display: flex; align-items: center; justify-content: center;
					position: relative;
					visibility: hidden;

					@include breakpoint(1000px) {
						visibility: visible;
					}

					.PatientVideo{
						flex: 1;

						margin-right: 1em;

						.video__el{
							width: 100%;
						}
					}
				}
			}

			.Examination{
				flex: 1;
				display: flex; flex-direction: row; align-items: center; justify-content: center;

				> div{
					@extend %hover;

					position: relative;
					width: 225px; height: 225px;
					margin: 2em;

					.image{
						display: block;
						background-color: #fff;
						background-size: cover;
						background-position: 50% 0;
						width: 225px; height: 225px;
					}

					&.Oculomotility.Chart .image{
						background-image: url('/images/oculomotilitychart.png');
					}

					&.Slit.Lamp .image{
						background-image: url('/images/slitlamp.jpg');
					}

					&.Ophthalmoscope .image{
						background-image: url('/images/ophthalmoscope.jpg');
					}

					&.Snellen .image{
						background-image: url('/images/snellenchart.jpg');
					}

					.title{
						display: block;
						background: rgba(255,255,255,0.7);
						font-size: 1.2em; color: $blue; font-weight: bold; text-align: center;
						padding: 1em;
					}
				}
			}

			.Hospital{
				flex: 1;
				display: flex; flex-direction: row; align-items: center; justify-content: center; align-content: center;
				flex-wrap: wrap;

				.empty{
					font-size: 1.4em; text-align: center;
					width: 500px;
				}

				.note{
					font-size: 1.2em; font-weight: 300; font-style: italic; text-align: center;
					width: 100%;
				}

				> div{
					@extend %hover;

					position: relative;
					width: 160;
					margin: 1em 2em;

					@include breakpoint($tablet) {
						width: 190px;
					}

					@include breakpoint($laptop) {
						width: 225px;
					}

					.image{
						display: block;
						background-color: #fff;
						background-size: cover;
						background-position: 50% 0;
						width: 160px; height: 160px;

						@include breakpoint($tablet) {
							width: 190px;
						}

						@include breakpoint($laptop) {
							width: 225px;
						}
					}

					.title{
						display: block;
						background: rgba(255,255,255,0.7);
						font-size: 1em; color: $blue; font-weight: bold; text-align: center;
						width: 160px;
						padding: 1em;

						@include breakpoint($tablet) {
							font-size: 1.1em;
							width: 190px;
						}

						@include breakpoint($laptop) {
							font-size: 1.2em;
							width: 225px;
						}
					}
				}
			}

			.Feedback{
				flex: 1;

				.wrap{
					background: #fff;
					font-size: 1.2em;
					padding: 5.5em 4em; margin-top: 5em;
					box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);

					h2,
					h3{
						font-weight: 600;
						margin-bottom: 0.75em;

						em{
							display: block;
							font-size: 0.75em; color: #2c2c2c; font-weight: 300; font-style: normal;
						}
					}

					h2{
						clear: both;
						margin-top: 3em; margin-bottom: 1.5em;

						&:first-child{
							color: #168809;
							margin-top: 0;
						}
					}

					h3{
						border-bottom: 1px solid rgba(0,0,0,0.07);
						padding-bottom: 0.3em; margin-top: 2em;
					}

					.group{

						.row{
							@include selfclear();

							margin-bottom: 0.5em;

							&.large{
								> .fieldHolder{
									font-weight: 600;
								}
							}

							.label{
								float: left;
							}

							.fieldHolder{
								font-style: italic;
								margin-left: 200px;
							}
						}
					}

					.reset{
						@extend %hover;

						display: inline-block;
						border: 1px solid $blue;
						color: $blue;
						padding: 1em 5em;
						margin-top: 3em;
						border-radius: 2px;
					}

					iframe{
						border: 0 none;
						width: 100%; min-height: 700px;
					}
				}
			}
		}
	}

	.Bottom{

		.Progress{
			display:flex;
			flex-direction: row;

			div {
				flex: 1;
				padding: 0.5em 0;
				font-size: 1.8em; font-weight: 600; color: #fff; text-align: center;
				text-align: center;
				text-transform: capitalize;
				background: $black;

				&.current {
					background: lighten($blue, 10%);
				}

				&.disabled {
					color: darken(#fff, 20%);
					background: lighten($black,  30%);

					span {
						cursor: not-allowed;
					}
				}

				&.feedback {
					flex:0;
					display: none;
					width: 0;
					visibility: hidden;
				}

				span{
					cursor: pointer;
				}
			}
		}

		.buttons{

			.label{
				@extend %hover;

				font-size: 1.8em; color: #fff; line-height: 4; font-weight: 300; text-align: center;
				width: 50%;

				.icon{
					font-size: 26px;
					vertical-align: middle;
				}
			}

			.button .modal{
				top: 100%!important;
				opacity: 0;
			}

			.modal{
				@include mask();

				flex: 1;
				display: flex; flex-direction: column;
				background: #ECECEC;
				color: #fff;
				z-index: 2;
				opacity: 1;
				transition: all 0.35s ease-in-out;

				@include breakpoint($tablet) {
					top: auto;
					max-height: 100%;
					min-height: 50%;
				}

				.top{
					display: flex;
					background: #fff;
					color: $blue; text-align: center;
					height: 28px;
					padding: 1.25em;
					box-sizing: content-box; // combine height and padding and content-box to fix ridiculous sizing bug

					.icon{
						font-size: 28px;
					}

					.title{
						flex: 1;
						font-size: 1.8em; font-weight: 600; line-height: 28px;
					}

					.close{
						@extend %hover;

						float: right;
					}

					// debug reset case icon
					.icon-left{
						@extend %hover;

						float: right;
					}
				}

				.middle{
					flex: 1;
					overflow-y: auto;
					padding: 2em;
				}

				.bottom{
					display: block;
					margin: 0 2em 2em 2em;

					.actions{
						display: flex;

						button{
							@extend %hover;

							transform: translate3d(0,0,0);

							display: block;
							background: transparent;
							border: 1px solid $blue;
							font-size: 1.6em; color: $blue; font-weight: 600;
							padding: 1em;

							&:first-child{
								border-color: rgba(18, 65, 106, 0.17);
								color: rgba(18, 65, 106, 0.6);
								margin-right: 0.5em;
							}

							&:last-child{
								flex: 1;
							}
						}
					}
				}
			}

			.Notes{

				.label{
					float: left;
					background: #fff;
					border-right: 1px solid rgba(0,0,0,0.13);
					color: $blue; font-weight: 600;

					.icon{
						position: relative; top: -1px;
						margin-right: 0.25em;
					}
				}

				.modal{
					display: flex; flex-direction: column;
					border-right: 1px solid #CECECE;

					@include breakpoint($tablet) {
						top: 70%;
						right: 50%;
					}
				}

				.middle{
					flex: 1;
					display: flex; flex-direction: column;

					form{

						.row{
							flex: 1;
							display: flex;

							textarea{
								flex: 1;
							}
						}
					}
				}
			}

			.Diagnose{

				.label{
					float: right;
					background: #fff;
					color: $blue; font-weight: 600;

					.icon{
						position: relative; top: -2px;
						margin-right: 0.4em;
					}
				}

				.modal{
					display: flex; flex-direction: column;

					@include breakpoint($tablet) {
						left: 50%;
					}

					.middle{
						padding-top: 2.5em;
					}
				}
			}

			form{
				flex: 1;
				display: flex; flex-direction: column;

				h2{
					font-size: 1.8em; color: #2c2c2c; font-weight: 300;
				}

				.inset{
					border-left: 5px solid rgba(0,0,0,0.10);
					padding: 0.5em 0 0.5em 1em;
					margin: 1.5em 0;

					select{
						margin-bottom: 0!important;
					}
				}

				.row{
					margin-bottom: 1.25em;
					border: 0;

					&:last-child{
						margin-bottom: 0;
					}

					label{
						display: inline-block;
						font-size: 1.6em; color: $blue; font-weight: 600;
						margin-bottom: 0.5em;
					}

					input, textarea, select{
						display: block;
						background: #fff;
						border: 0 none;
						font-size: 1.5em; color: $black;
						width: 100%;
						padding: 1em;
						border-radius: 0 0 5px 5px;
					}

					textarea{
						min-height: 7em;
					}

					select{
						margin-bottom: 0.25em;
					}

					.radio{
						background: #fff;
						padding: 0.75em;

						> div{
							display: inline-block;
							color: $black;
							margin-right: 1em;

							input{
								display: inline-block;
								width: auto;
								padding: 0; margin: 0 0.2em 0 0;
								vertical-align: middle;
							}

							span{
								font-size: 1.4em;
								vertical-align: middle;
							}
						}
					}
				}
			}
		}
	}

	.mask{
		@include mask();

		opacity: 0;
		transition: all 0.15s;
		z-index: -1;
	}
}

.Question{
	@extend %hover;

	position: relative;

	.icon,
	.name{
		transition: color 0.25s ease-in-out;
	}

	&.disabled{
		color: rgba(0,0,0,0.2);
		cursor: default;
	}

	&.hasPlayed{
		color: rgba(0,0,0,0.4);
	}

	.icon{
		position: relative;
	}

	.tellMeMore{
		position: absolute; top: -0.5em; right: 0.5em;
		display: block;
		background: #558B2F;
		font-family: $opensans; font-size: 0.3em; color: #fff; text-align: center; line-height: 1.5em;
		width: 1.5em; height: 1.5em;
		border-radius: 2em;
		transition: background 0.2s ease-in-out;

		&:hover{
			background: lighten(#558B2F, 8%);
		}
	}

	$pulsateOpacity: 0.4;

	.playing{
		position: absolute; bottom: 0; right: 0.5em;

		background: $blue;
		font-size: 0.3em!important; color: #fff!important; text-align: center; line-height: 1.5em;
		width: 1.5em; height: 1.5em;
		border-radius: 2em;
		-webkit-animation: pulsate 2s ease-out;
		-webkit-animation-iteration-count: infinite;
		opacity: $pulsateOpacity;

		&::before{
			font-size: 1.2em;
		}
	}

	@-webkit-keyframes pulsate {
		0% {opacity: $pulsateOpacity;}
		50% {opacity: 1.0;}
		100% {opacity: $pulsateOpacity;}
	}
}

// lazy exception, should just be done with flex..
.hospital .Diagnose textarea{min-height: 19em!important;}

// examination stage exception for size of form
.examination .Diagnose .modal{
	@include breakpoint($tablet) {
		top: 18%;
	}
}

// redux-devtools fix
.Viewport ~ div{max-width: 100%!important;}
